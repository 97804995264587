import React from "react";

function List(){
    return(
        <>
        <section id="categories">
        <div className="container">
          <div className="row">
            <div className="categories_1 clearfix">
              <div className="col-sm-12">
                <h2 className="mgt">Listing</h2>
                <hr />
              </div>
            </div>
            <div className="categories_2 clearfix">
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture1.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Open Type</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture2.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-J1 Type</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture3.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Submersible</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture4.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Grundfous</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture5.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Grundfous</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture6.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Grundfous</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture7.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Grundfous</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture8.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Grundfous</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture9.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-CNP Pump</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture10.png" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Modi Type</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture11.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-MG-1-12-13</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture12.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Robin Type</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture13.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Double Robin</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture14.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Close Type</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture15.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-JhonCran</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture16.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Single Spring</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture17.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-Conicle</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture18.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-2100 Type</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture19.png" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-TefLone Bellow</a></h5>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="categories_2i text-center clearfix">
                  <a href="#"><img src="./image/Picture20.jpg" className="listimg" alt="abc" /></a>
                  <h5><a href="#">Hexa-502</a></h5>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </section>
        </>
    )
}

export default List