import React from "react";

function Connect() {
    return (
        <>
            <section id="connect" className="clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 space_all">
                            <div className="connect_1 clearfix">
                                <div className="col-sm-3 connect_1m">
                                    <div className="connect_1l clearfix">
                                        <span><i className="fa fa-truck" /></span>
                                    </div>
                                    <div className="connect_1r clearfix">
                                        <h4>Fast Shipping</h4>
                                        <p>Fast shipping world wide</p>
                                    </div>
                                </div>
                                <div className="col-sm-3 connect_1m">
                                    <div className="connect_1l clearfix">
                                        <span><i className="fa fa-headphones" /></span>
                                    </div>
                                    <div className="connect_1r clearfix">
                                        <h4>Support 24/7</h4>
                                        <p>Contact us 24 hours a day</p>
                                    </div>
                                </div>
                                <div className="col-sm-3 connect_1m">
                                    <div className="connect_1l clearfix">
                                        <span><i className="fa fa-credit-card" /></span>
                                    </div>
                                    <div className="connect_1r clearfix">
                                        <h4>Secure Payments</h4>
                                        <p>100% payment protection</p>
                                    </div>
                                </div>
                                <div className="col-sm-3 connect_1m border_none">
                                    <div className="connect_1l clearfix">
                                        <span><i className="fa fa-shopping-cart" /></span>
                                    </div>
                                    <div className="connect_1r clearfix">
                                        <h4>Easy Return</h4>
                                        <p>Simple returns policy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Connect