import React from "react";

function Header() {
  return (

    <section id="header">
      <div className="container" id="dis">
        <div className="row">
          <div className="header_1 clearfix">
            <div className="col-sm-3">
              <div className="header_1l clearfix">
                <h2 className="mgt">
                  <div className="navbar nnb">
                    <img src="./image/logo1.jpg" className = "tec"/>
                    <div className="navbar-text bcv">HexaTech</div>
                  </div>
                </h2>
              </div>
            </div>
            {/* <div className="col-sm-6">
              <div className="header_1m clearfix">
                <div className="input-group">
                  <input type="text" className="form-control" placeholder="Search" />
                  <span className="input-group-btn">
                    <button className="btn btn-primary" type="button">
                      <i className="fa fa-search" /></button>
                  </span>
                </div>
              </div>
            </div> */}
            <div className="header_1r clearfix">

              
              <ul className="nav navbar-nav mgt navbar-right">
                <li className="dropdown">
                  <a className="tag_m1" href={"https://wa.me/918850440600?text=Hello%21%20I%E2%80%99d%20like%20to%20inquire%20about%20purchasing%20a%20water%20pump%20seal.%20Could%20you%20please%20provide%20more%20information%3F%20Thank%20you%21"} data-toggle="dropdown" role="button" aria-expanded="false">
                    <i className="fa fa-whatsapp eco"/>
                  </a>
                </li>
                <li className="dropdown">
                  
                  {/* <a className="tag_m1" href={"tel:+918850440600"} data-toggle="dropdown" role="button" aria-expanded="false">
                    <i className="fa fa-phone" style={{fontSize: '36px', color: '#16AEF0' ,marginTop: '-10px'}} />
                  </a> */}
                  <a href={"tel:+918850440600"}>
                  <span className="icon-phone trin-trin header_1l clearfix cco ju"></span>
                  </a>
                  
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header