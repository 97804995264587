

import React from "react";
import Top from "./Components/Top";
import Header from "./Components/Header";
import Main from "./Components/Section/Main";
import Center from "./Components/Section/Center";
import Cetegories from "./Components/Section/Cetegories";
import List from "./Components/Section/List";
import Connect from "./Components/Section/Connect";
import Footer from "./Components/Footer";
import About from "./Components/Section/About";
import FixedIcons from "./Components/FixedIcons";


function App(){
    return(
        <>
     
        <Top></Top>
        <Header></Header>
        {/* <Main></Main> */}
         <Center></Center>
        <Cetegories></Cetegories>
        <List></List> 
        {/* <About></About> */}
        <Connect></Connect>
        <FixedIcons></FixedIcons>
        <Footer></Footer>
        
        </>
    )
}

export default App