import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone ,faArrowUp} from '@fortawesome/free-solid-svg-icons';


const FixedIcons = () => {
  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 100) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 100) {
      setShowScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  return (
    <div style={styles.container}>
      <a
        href="https://wa.me/918850440600?text=Hello%21%20I%E2%80%99d%20like%20to%20inquire%20about%20purchasing%20a%20water%20pump%20seal.%20Could%20you%20please%20provide%20more%20information%3F%20Thank%20you%21"
        style={styles.icon}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} size="2x" color="#25D366" />
      </a>
      <a href="tel:+918850440600" style={styles.icon}>
        <FontAwesomeIcon icon={faPhone} size="2x" color="#16AEF0" />
      </a>
      
      <div
        style={{ ...styles.scrollUp, display: showScroll ? 'block' : 'none' }}
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon={faArrowUp} size="2x" color="#000" />
      </div>

    </div>
  );
};

const styles = {
  container: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  icon: {
    textDecoration: 'none',
  },
  scrollUp: {
    cursor: 'pointer',
  },
};

export default FixedIcons;
