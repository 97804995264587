import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './css/bootstrap.min.css'
import './css/font-awesome.min.css'
// import { Glyphicon } from 'react-bootstrap';
import './css/Global.css'
import './css/index.css'

import './css/Fontstyle.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
        <App></App>
        </BrowserRouter>
    </React.StrictMode>
);


// import Reactdom from 'react-dom/client'

// Reactdom.createRoot(document.getElementById('root'));