import React from "react";
// import { Glyphicon } from 'react-bootstrap/lib/Glyphicon';

function Center (){
    return (
        <>
            <section id="center" className="clearfix center_home">
                <div className="container">
                    <div className="row">
                        <div className="center_home_1 clearfix">
                            <div id="carousel-example" className="carousel slide hidden-xs" data-ride="carousel">
                            
                                <div className="carousel-inner">
                                    <div className="item active">
                                    <div className="col-sm-6">
                                            <div className="center_home_r clearfix">
                                                <img src="./image/main.png" className="iw" alt="abc"></img>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="center_home_l clearfix">
                                                <h4 className="mgt">World Best Quality</h4>
                                                <h1 className = 'seal'>Water Pump Mechanical Seal </h1>
                                                <hr></hr>
                                                    <p>Upgrade to the Best – Secure Your Pump with Our Top-Tier Seals!</p>
                                                    <p>With over a decade of experience, we specialize in manufacturing and selling top-quality water pump seals. Since 2011, our commitment to precision engineering and premium materials has established us as a trusted leader in the industry. Our seals are designed to deliver unmatched durability and performance, ensuring optimal protection and longevity for your water pumps. Join countless professionals worldwide who rely on our expertise for reliable and superior sealing solutions.</p>
                                                    <p>We are a dedicated team of engineers and specialists committed to manufacturing high-quality water pump seals. With years of experience and a passion for excellence, we create durable and dependable seals that ensure the optimal performance of water pumps. Our expertise in precision engineering allows us to produce seals that meet the highest industry standards, providing our clients with reliable solutions that stand the test of time.</p>
                                                    <h5 className="normal"><a className="button" href="tel:+918850440600" >Contact Us</a></h5>
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-6">
                                            <div className="center_home_r clearfix">
                                                <img src="./image/main.png" className="iw" alt="abc"></img>
                                            </div>
                                        </div> */}
                                    </div>

                                    {/* <div className="item" id="next">
                                        <div className="col-sm-6">
                                            <div className="center_home_l clearfix">
                                                <h4 className="mgt">World Best Quality</h4>
                                                <h1>Elit Sed Do Eiu</h1>
                                                <hr></hr>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                                                    <h5 className="normal"><a className="button" href="#">Shopping Now</a></h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="center_home_r clearfix">
                                                <img src="./image/image01.jpg" className="iw" alt="abc"></img>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {/* <div className="sell_1 clearfix">

                                <div className="col-sm-12">
                                    <div className="controls text-center">
                                        <a className="left fa fa-chevron-left btn btn-success" href="next" data-slide="prev"></a>
                                        <a className="right fa fa-chevron-right btn btn-success" href="#carousel-example" data-slide="next"></a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Center