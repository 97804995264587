import React from "react";

function Cetegories() {
    return (
        <>
            <section id="Categ">
                <div className="container">
                    <div className="row">
                        <div className="Categ_1 clearfix">
                            <div className="col-sm-12">
                                <h3 className="mgt">Popular Categories</h3>
                                <hr />
                            </div>
                        </div>
                        <div className="Categ_2 clearfix">
                            <div className="col-sm-3">
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image01.jpeg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-MG Type</a></h4>
                                    {/* <h6>(10 Items)</h6> */}
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image02.jpg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-Single Spring</a></h4>
                                    {/* <h6>(12 Items)</h6> */}
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image04.jpg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-Conical</a></h4>
                                    {/* <h6>(15 Items)</h6> */}
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image06.jpg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-MG</a></h4>
                                    {/* <h6>(20 Items)</h6> */}
                                </div>
                            </div>
                        </div>
                        <div className="Categ_2 clearfix">
                            <div className="col-sm-3">
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image07.jpg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-Robin Type</a></h4>
                                    {/* <h6>(30 Items)</h6> */}
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image08.jpg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-Single Spring</a></h4>
                                    {/* <h6>(20 Items)</h6> */}
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image09.jpg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-MG</a></h4>
                                    {/* <h6>(10 Items)</h6> */}
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image10.jpg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-Parts Box</a></h4>
                                    {/* <h6>(25 Items)</h6> */}
                                </div>
                            </div>
                            <div className="col-sm-3 heximg">
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image11.jpg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-GrandFose Catreg</a></h4>
                                    {/* <h6>(25 Items)</h6> */}
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-sm-3 heximg" >
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image12.jpg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-Robin Type</a></h4>
                                    {/* <h6>(25 Items)</h6> */}
                                </div>
                            </div>
                            <div className="col-sm-3 heximg">
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image13.jpg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-MG</a></h4>
                                    {/* <h6>(25 Items)</h6> */}
                                </div>
                            </div>
                            <div className="col-sm-3 heximg" >
                                <div className="Categ_2i clearfix">
                                    <a href="#"><img src="./image/image14.jpg" alt="abc" /></a>
                                    <h4 className="mgt cont"><a href="#">Hexa-M7N</a></h4>
                                    {/* <h6>(25 Items)</h6> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cetegories