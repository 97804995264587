import React from "react";

function Top() {
    return (
        <>
            <section id="top">
                <div className="container">
                    <div className="row">
                        <div className="top_1 clearfix">
                            <div className="col-sm-12">
                                <div className="top_1l clearfix">
                                    <span className="col_1"><p className="mgt small col_1">Welcome to HexaTech Trading Co.</p></span>
                                </div>
                            </div>
                            {/* <div className="col-sm-9">
                                <div className="top_1r clearfix">
                                    <ul className="nav navbar-nav nav_1 mgt navbar-right">

                                        <li className="dropdown bord_l">
                                            <a className="font_tag border_none" href="#" data-toggle="dropdown" role="button" aria-expanded="false">Setting<span className="caret"></span></a>
                                            <ul className="dropdown-menu drop_1" role="menu">
                                                <li><a href="#">Checkout</a></li>
                                                <li><a href="#">My Account</a></li>
                                                <li><a href="#">Shopping Cart</a></li>
                                                <li><a href="#">Wishlist</a></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown bord_l">
                                            <a className="font_tag border_none" href="#" data-toggle="dropdown" role="button" aria-expanded="false">$ USD<span className="caret"></span></a>
                                            <ul className="dropdown-menu drop_1" role="menu">
                                                <li><a href="#">EUR  Euro</a></li>
                                                <li><a href="#">GBP  British Pound</a></li>
                                                <li><a href="#">INR  India Rupee</a></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown bord_l">
                                            <a className="font_tag border_none" href="#" data-toggle="dropdown" role="button" aria-expanded="false">English<span className="caret"></span></a>
                                            <ul className="dropdown-menu drop_1" role="menu">
                                                <li><a href="#"><i className="fa fa-flag"></i> English</a></li>
                                                <li><a href="#"><i className="fa fa-flag"></i> Germany</a></li>
                                            </ul>
                                        </li>
                                        <li className="bord_l border_none"><a className="font_tag" href="#"><i className="fa fa-repeat"></i> Compare (0)</a></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Top